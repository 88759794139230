<template>
  <v-container
    fluid
    class="container-profile"
  >
    <v-row>
      <v-col>
        <div>
          <p class="text-title mon-bold">{{ texts.userprofile.textTitle }}</p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div>
          <v-tabs
            v-model="tab"
            show-arrows
            background-color="transparent"
          >
            <v-tabs-slider class="separator-line-tab"></v-tabs-slider>
            <v-tab class="tabs-profile mon-regular">{{ texts.userprofile.tabUserData }}</v-tab>
            <v-tab class="tabs-profile mon-regular">{{ texts.userprofile.tabEnterpriseData }}</v-tab>
            <v-tab class="tabs-profile mon-regular">{{ texts.userprofile.tabDocuments }}</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
            <v-tab-item>
              <user-profile-content-tab-one-layout
                :texts="texts"
                :tab="tab"
              />
            </v-tab-item>
            <v-tab-item>
              <user-profile-content-tab-two-layout
                :texts="texts"
                :tab="tab"
              />
            </v-tab-item>
            <v-tab-item>
              <user-profile-content-tab-three-layout
                :texts="texts"
                :tab="tab"
              />
            </v-tab-item>
          </v-tabs-items>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "UserProfileContentLayout",
  props: {
    texts: {
      type: Object,
    },
  },
  data() {
    return {
      //VARIABLES
      tab: 0,
    };
  },
  beforeMount() {},
  methods: {},
  computed: {},
  watch: {},
};
</script>

<style>
.container-profile .v-slide-group__content {
  margin-bottom: 4px !important;
}
</style>

<style scoped>
.text-title {
  text-align: left;
  font-size: 30px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.separator-line-tab {
  background: transparent linear-gradient(268deg, #fffc81 0%, #7eff86 100%) 0%
    0% no-repeat padding-box;
  height: 40px;
  margin-left: 2px;
}

.tabs-profile {
  font-size: 14px;
  text-transform: initial;
  letter-spacing: 0px;
  text-align: center;
  width: 200px;
  min-width: 200px;
  max-width: 200px;
}

.v-tab--active {
  font-family: "montserrat-bold", sans-serif;
  color: #3d414a !important;
}

.v-tabs-items {
  background-color: transparent !important;
}

.theme--light.v-tabs .v-tab:focus::before {
  opacity: 0;
}

.theme--light.v-tabs .v-tab--active:hover::before,
.theme--light.v-tabs .v-tab--active::before {
  opacity: 0;
}
</style>